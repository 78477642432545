



























import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import AppListExpense from '@/components/AppListExpense.vue';
import EXPENSES from '@/graphql/queries/Expenses.graphql';
import { Expense } from '@/types/schema';

export default Vue.extend({
  name: 'DriverExpenses',
  components: {
    AppMain,
    AppEmpty,
    AppListExpense,
  },
  data() {
    return {
      expenses: [] as Expense[],
    };
  },
  computed: {
    hasExpenses(): boolean {
      return this.expenses.length > 0;
    },
  },
  apollo: {
    expenses: {
      query: EXPENSES,
      fetchPolicy: 'cache-and-network',
    },
  },
});
